import AgoraRTC, { IAgoraRTCClient, IAgoraRTCRemoteUser, UID } from 'agora-rtc-sdk-ng';

import axios from 'axios';
import { RecordingInfo } from '../pages/[shopSlug]/shows/[showSlug]/go-live';

export type RTC = {
  localAudioTrack: any | null;
  localVideoTrack: any | null;
  client: IAgoraRTCClient | null;
  compositor: any | null;
};

export type RTCClientOptions = {
  appId: string;
  channel: string | null;
  token: string | null;
  uid: number | null; // User ID
};

export const displayRemoteVideo = (user: any) => {
  const remotePlayerContainer = document.createElement('div');
  remotePlayerContainer.id = user.uid.toString();
  remotePlayerContainer.classList.add('local-viewer');
  remotePlayerContainer.style.width = '430px';
  remotePlayerContainer.style.height = '480px';
  document.body.append(remotePlayerContainer);
  user?.videoTrack?.play(remotePlayerContainer);
};

export const getNumberOfUsersInChannel = async (channel: string, callback?: (audienceTotal: number) => void) => {
  const customerKey = process.env.NEXT_PUBLIC_AGORA_CUSTOMER_KEY;
  // Customer secret
  const customerSecret = process.env.NEXT_PUBLIC_AGORA_CUSTOMER_SECRET;
  // Concatenate customer key and customer secret and use base64 to encode the concatenated string
  const plainCredential = customerKey + ':' + customerSecret;
  // Encode with base64
  const encodedCredential = Buffer.from(plainCredential).toString('base64');
  const authorizationField = 'Basic ' + encodedCredential;

  try {
    const response = await axios.get(
      `https://api.sd-rtn.com/dev/v1/channel/user/${process.env.NEXT_PUBLIC_AGORA_APP_ID}/${channel}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorizationField
        }
      }
    );

    callback?.(response.data.data.audience_total);
    return response.data;
  } catch (error) {
    console.error('Error during the API request:', error);
    throw error;
  }
};

export const handleRemoteUsers = (
  rtc: RTC,
  isHost: boolean,
  options: RTCClientOptions,
  onRemoteUserJoined?: (user: IAgoraRTCRemoteUser) => void,
  onAudioAutoPlayFailure?: () => void,
  onRemoteUserUnpublished?: () => void
) => {
  rtc.client?.on('user-published', async (user: IAgoraRTCRemoteUser, mediaType: 'video' | 'audio') => {
    await rtc.client?.subscribe(user, mediaType);
    console.log('subscribe success');

    // getNumberOfUsersInChannel(options)

    if (mediaType === 'video') {
      if (isHost) {
        const endpointTwo = rtc.compositor?.createInputEndpoint({
          x: 480,
          y: 270,
          width: 320,
          height: 180,
          fit: 'cover'
        });

        user?.videoTrack?.pipe(endpointTwo).pipe(user.videoTrack.processorDestination);
      } else {
        displayRemoteVideo(user);
      }
    }

    if (mediaType === 'audio') {
      // user?.audioTrack?.play()

      if (!user?.audioTrack?.isPlaying) {
        onAudioAutoPlayFailure?.();
      }
    }

    onRemoteUserJoined?.(user);
  });

  rtc.client?.on('user-unpublished', (user: any) => {
    onRemoteUserUnpublished?.();
    const remotePlayerContainer = document.getElementById(user?.uid.toString());
    if (remotePlayerContainer) {
      remotePlayerContainer.remove();
    }
  });
};

export const initializeAgoraRTCClient = async (rtc: RTC, isHost: boolean) => {
  const { default: AgoraRTC } = await import('agora-rtc-sdk-ng');

  const { default: VideoCompositingExtension } = await import('agora-extension-video-compositor');

  rtc.client = AgoraRTC.createClient({
    mode: 'live',
    codec: 'h264',
    role: isHost ? 'host' : 'audience'
  });

  const extension = new VideoCompositingExtension();
  AgoraRTC.registerExtensions([extension]);
  rtc.compositor = extension.createProcessor();

  // handleRemoteUsers(rtc, isHost, options);

  return rtc;
};

export const createAndPublishLocalTracks = async (rtc: RTC) => {
  const { default: AgoraRTC } = await import('agora-rtc-sdk-ng');

  // Dynamically get the screen dimensions
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const sourceVideoTrackOne = await AgoraRTC.createCameraVideoTrack({
    facingMode: 'user'
  });
  const sourceAudioTrackOne = await AgoraRTC.createMicrophoneAudioTrack();

  // Create input endpoint with dynamic dimensions
  const endpointOne = rtc.compositor?.createInputEndpoint({
    x: 0,
    y: 0,
    width: screenWidth,
    height: screenHeight,
    fit: 'cover'
  });

  sourceVideoTrackOne.pipe(endpointOne).pipe(sourceVideoTrackOne.processorDestination);

  const canvas = document.createElement('canvas');
  canvas.width = screenWidth;
  canvas.height = screenHeight;

  rtc.localVideoTrack = AgoraRTC.createCustomVideoTrack({
    mediaStreamTrack: canvas.captureStream().getVideoTracks()[0],
    height: screenHeight,
    width: screenWidth
  });

  // Set output options with dynamic dimensions
  rtc.compositor.setOutputOptions(screenWidth, screenHeight, 15);
  await rtc.compositor.start();

  rtc.localVideoTrack.pipe(rtc.compositor).pipe(rtc.localVideoTrack.processorDestination);

  rtc.localAudioTrack = sourceAudioTrackOne;
};

//     const uid = Math.floor(Math.random() * 10000);

//     const tokenResponse = await fetch(`${window.location.origin}/api/live/create-rtc-token`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           uid: uid,
//           role: "host",
//         }),
//       });

//       if (!tokenResponse.ok) {
//         throw new Error(`Failed to fetch token: ${tokenResponse.statusText}`);
//       }

//       const tokenData = await tokenResponse.json();

//     const response = await fetch('/api/live/recording/start', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//             cname: options.channel,
//             uid: `${uid}`,
//             token: tokenData.token,
//         }),
//     });

//     const data = await response.json();
//     console.log('Start Response:', data);
// };

export const startRecording = async (options: RTCClientOptions, onSuccess?: (data?: RecordingInfo) => void) => {
  try {
    const uid = Math.floor(Math.random() * 10000);
    const tokenUrl = `${window.location.origin}/api/live/create-rtc-token`;

    // Fetch RTC token
    const tokenResponse = await fetch(tokenUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ uid, role: 'host' })
    });

    if (!tokenResponse.ok) {
      throw new Error(`Failed to fetch token: ${tokenResponse.status} ${tokenResponse.statusText}`);
    }

    const { token } = await tokenResponse.json();

    // Start recording
    const recordingResponse = await fetch('/api/live/recording/start', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ cname: options.channel, uid: String(uid), token })
    });

    if (!recordingResponse.ok) {
      throw new Error(`Failed to start recording: ${recordingResponse.status} ${recordingResponse.statusText}`);
    }

    const data = await recordingResponse.json();
    // console.log('Recording started successfully:', data);

    onSuccess?.(data);

    return data;
  } catch (error) {
    console.error('Error starting recording:', error);
    throw error;
  }
};

export const stopRecording = async (recordingInfo: RecordingInfo) => {
  const response = await fetch('/api/live/recording/stop', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      resourceId: recordingInfo.resourceId,
      sid: recordingInfo.sid,
      cname: recordingInfo.cname,
      uid: recordingInfo.uid
    })
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || 'Failed to stop recording');
  }

  return data;
};

export const publishStream = async (rtc: RTC) => {
  await rtc.client?.publish([rtc.localVideoTrack, rtc.localAudioTrack]);
};

export const unPublishStream = async (rtc: RTC, onSuccess?: () => void) => {
  // await rtc?.client?.unpublish([rtc.localVideoTrack, rtc.localAudioTrack]);
  await rtc?.client?.leave();
  onSuccess?.();
};

export const leaveChannel = async (
  rtc: RTC,
  uid: number,
  rtmpConverterId?: string,
  fbVideoId?: string,
  fbAccessToken?: string,
  onSuccess?: () => void
) => {
  // if (rtc.localAudioTrack) {
  //     rtc.localAudioTrack.close();
  //     rtc.localAudioTrack = null;
  // }

  // if (rtc.localVideoTrack) {
  //     rtc.localVideoTrack.close();
  //     rtc.localVideoTrack = null;
  // }

  // const localPlayerContainer = document.getElementById(uid.toString());
  // if (localPlayerContainer) {
  //     localPlayerContainer.remove();
  // }

  // console.log("rtmp: ", rtmpConverterId, "fbVideoId: ", fbVideoId, "fbAccessToken: ", fbAccessToken);

  if (rtmpConverterId && fbVideoId && fbAccessToken) {
    // console.log("Fb unpublish logic ran");
    await unPublishFacebookStream(rtmpConverterId, fbVideoId, fbAccessToken);
  }

  rtc?.client?.remoteUsers.forEach(() => {
    const playerContainer = document.getElementById(uid.toString());
    if (playerContainer) {
      playerContainer.remove();
    }
  });

  try {
    unPublishStream(rtc, onSuccess);
    console.log('Left the channel.');
  } catch (error) {
    console.error('Error leaving the channel:', error);
  }
};

export const leaveChatRoom = async (rtc: RTC, uid: number, onSuccess?: () => void) => {
  if (rtc?.client?.leave) {
    try {
      await rtc.client.leave();

      rtc?.client?.remoteUsers.forEach(() => {
        const playerContainer = document.getElementById(uid.toString());
        if (playerContainer) {
          playerContainer.remove();
        }
      });

      console.log('Successfully left chat room');
      onSuccess?.();
    } catch (error) {
      console.error('Error leaving chat room:', error);
    }
  }
};

export const joinAsHost = async (rtc: RTC, options: RTCClientOptions, onSuccess?: () => void) => {
  const { appId, channel, token, uid } = options;
  try {
    await rtc.client?.join(appId, channel!, token, uid);
    // rtc.client?.setClientRole('host');

    if (rtc.client?.connectionState === "CONNECTED") {
      await createAndPublishLocalTracks(rtc);
      onSuccess?.();
      console.log('Host joined and published tracks.');
    }
  } catch (error) {
    console.error('Error joining as host:', error);
  }
};

export async function getFbPersistentStreamKey(pageId: string, accessToken: string): Promise<any> {
  try {
    const url = `https://graph.facebook.com/v18.0/${pageId}/live_videos`;

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };

    const data = {
      persistent_stream_key: true
    };

    const response = await axios.post(url, data, { headers });

    return response.data; // Returns the response from Facebook
  } catch (error: any) {
    console.error('Error fetching persistent stream key:', error.response?.data || error.message);
    throw new Error(error.response?.data?.error?.message || 'Failed to get stream key');
  }
}

export const publishStreamToFacebook = async (
  channel: string,
  rtmpUrl: string,
  hostUid: number,
  onSuccess?: (rtmpConverterId: string) => void
) => {
  try {
    const response = await axios.post(
      '/api/live/fb',
      { channel, rtmpUrl, hostUid },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    onSuccess?.(response?.data?.converter?.id);
    return response.data;
  } catch (error) {
    console.error('Error during the API request:', error);
    throw error;
  }
};

export const unPublishFacebookStream = async (converterId: string, videoId: string, accessToken: string) => {
  try {
    const response = await axios.delete(
      `/api/live/fb?converterId=${converterId}&videoId=${videoId}&accessToken=${accessToken}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    // console.log('Response data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error during the API request:', error);
    throw error;
  }
};

export const joinAsAudience = async (rtc: RTC, options: RTCClientOptions, onSuccess?: () => void) => {
  const { appId, channel, token, uid } = options;

  try {
    await rtc.client?.join(appId, channel!, token, uid);
    rtc.client?.setClientRole('audience', { level: 1 });

    // disableJoinButtons();
    onSuccess?.();
    console.log('Audience joined.');
  } catch (error) {
    console.error('Error joining as host:', error);
  }
};

export const fetchAgoraChannelsOnServer = async (baseUrl: string) => {
  const response = await fetch(`${baseUrl}/api/live/get-channels`);
  const { data } = await response.json();
  return data.channels;
};

export const fetchAgoraChannels = async () => {
  const response = await fetch('/api/live/get-channels');
  const { data } = await response.json();
  return data.channels;
};

export async function postCommentToFacebook(fbPages: any, fbStreamInfo: any, query: any, baseUrl: string) {
  if (!fbPages) return;

  try {
    for (const page of fbPages) {
      const pageAccessToken = await getPageAccessToken(page);
      if (!pageAccessToken) continue;

      await delay(15000);

      const liveVideo = await getLiveVideo(page.id, pageAccessToken);
      if (!liveVideo) continue;

      const videoId = extractVideoIdFromEmbedHtml(liveVideo.embed_html);
      if (!videoId) {
        console.error(`Failed to extract video ID for ${page.name}`);
        continue;
      }

      const commentPosted = await postComment(videoId, page.name, pageAccessToken, query, baseUrl);
      if (commentPosted) {
        await updateVideoDescription(fbStreamInfo.videoId, page.name, pageAccessToken, query, baseUrl);
      }
    }
  } catch (error) {
    console.error('Error posting comment:', error);
  }
}

export async function getPageAccessToken(page: { id: string; name: string; access_token: string }) {
  try {
    const response = await fetch(`https://graph.facebook.com/v18.0/${page.id}?fields=access_token`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + page.access_token
      }
    });

    if (!response.ok) {
      console.error(`Failed to get access token for ${page.name}`, await response.json());
      return null;
    }

    const result = await response.json();
    return result.access_token;
  } catch (error) {
    console.error(`Error getting access token for ${page.name}:`, error);
    return null;
  }
}

export async function getLiveVideo(pageId: string, accessToken: string) {
  try {
    const response = await fetch(`https://graph.facebook.com/v18.0/${pageId}/live_videos`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    });

    if (!response.ok) {
      console.error(`Failed to fetch videos for page ID ${pageId}`, await response.json());
      return null;
    }

    const result = await response.json();
    return result.data.find((video: { status: string }) => video.status === 'LIVE');
  } catch (error) {
    console.error(`Error fetching live videos for page ID ${pageId}:`, error);
    return null;
  }
}

export function extractVideoIdFromEmbedHtml(embedHtml: string): string | null {
  const match = embedHtml.match(/videos%2F(\d+)/);
  return match ? match[1] : null;
}

export async function postComment(videoId: string, pageName: string, accessToken: string, query: any, baseUrl: string) {
  try {
    const response = await fetch(`https://graph.facebook.com/v18.0/${videoId}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      },
      body: JSON.stringify({
        message: `${pageName} is live now! Watch here: ${baseUrl}/live/${query.shopSlug}/${query.showSlug}`
      })
    });

    if (!response.ok) {
      console.error(`Failed to post comment to ${pageName}`, await response.json());
      return false;
    }

    return true;
  } catch (error) {
    console.error(`Error posting comment to ${pageName}:`, error);
    return false;
  }
}

export async function updateVideoDescription(
  videoId: string,
  pageName: string,
  accessToken: string,
  query: any,
  baseUrl: string
) {
  try {
    const response = await fetch(`https://graph.facebook.com/v18.0/${videoId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      },
      body: JSON.stringify({
        description: `${pageName} is live now! Join us here: ${baseUrl}/live/${query.shopSlug}/${query.showSlug}`
      })
    });

    if (!response.ok) {
      console.error(`Failed to update video description for ${pageName}`, await response.json());
    }
  } catch (error) {
    console.error(`Error updating video description for ${pageName}:`, error);
  }
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
