import Card from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import React, { forwardRef, ReactNode } from 'react';
import { colors } from '../../theme/colors';
import { colorTokens } from '../../theme/color-tokens';

interface CustomCardProps {
  children: ReactNode;
  selected?: boolean;
  selectedStyles?: string;
  onClick?: () => void;
  disabled?: boolean;
  margin?: string;
  borderRadius?: string;
  contentPadding?: string;
  disableShadow?: boolean;
  innerShadow?: boolean;
  border?: string;
  height?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

const CustomCard = forwardRef<HTMLDivElement, CustomCardProps>(
  (
    {
      disableShadow = false,
      children,
      selected,
      selectedStyles,
      onClick,
      disabled,
      margin,
      borderRadius,
      contentPadding,
      border = 'none',
      innerShadow = false,
      height,
      fullWidth,
      style,
      ...props
    },
    ref // 🔹 forwardRef receives 'ref' as the second argument
  ) => {
    return (
      <StyledCard
        {...props}
        ref={ref} // 🔹 Pass ref here
        selectedStyles={undefined}
        height={height}
        margin={margin}
        borderRadius={borderRadius}
        disabled={disabled}
        selected={selected}
        disableShadow={disableShadow}
        innerShadow={innerShadow}
        border={border}
        onClick={() => {
          if (!disabled) {
            onClick?.();
          }
        }}
        style={style}
        fullWidth={fullWidth}
      >
        <CardContent contentPadding={contentPadding}>{children}</CardContent>
      </StyledCard>
    );
  }
);

export default CustomCard;

const StyledCard = styled(Card)<CustomCardProps>`
  box-sizing: border-box;
  position: static !important;
  cursor: pointer;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '8px')} !important;
  margin: ${({ margin }) => (margin ? margin : '0 0 10px 0')};
  border: ${({ selected, disableShadow, border }) =>
    selected
      ? `1px solid ${colors.purple500}`
      : border
      ? border
      : disableShadow
      ? `1px solid ${colorTokens.borderDefault}`
      : 'none'};
  background: ${({ selected }) => (selected ? colors.purple50 : colors.white)};
  opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};
  box-shadow: ${({ disableShadow, innerShadow }) =>
    disableShadow
      ? 'none'
      : `0px 2px 8px 0px rgba(141, 143, 168, 0.22) ${
          innerShadow ? ', inset 0px -2px 8px 0px rgba(141, 143, 168, 0.22)' : ''
        }`};
  height: ${({ height }) => height ?? 'auto'};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

const CardContent = styled(MuiCardContent)<{ contentPadding?: string }>`
  && {
    padding: ${({ contentPadding }) => (contentPadding ? contentPadding : '16px')};
  }
`;

StyledCard.defaultProps = {
  selected: false
};
